import React, { useRef, useEffect } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import CloseIcon from "@mui/icons-material/Close";
import RoomIcon from "@mui/icons-material/Room";
import Map, {
  Marker,
  NavigationControl,
  ScaleControl,
  GeolocateControl,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
const ProjectPopup = ({ isOpen, onClose, project }) => {
  const popupRef = useRef();
  const mapRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        isOpen
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    // Reset the map's view state whenever the popup is opened
    if (isOpen && mapRef.current) {
      mapRef.current.jumpTo({
        center: [project.location.long, project.location.lat],
        zoom: 10,
        essential: true, // Ensures the animation always happens
      });
    }
  }, [isOpen, project]);

  const popupClassName = isOpen
    ? "profile-popup-container open"
    : "profile-popup-container closed";
  const popupClassName2 = isOpen ? "popup open" : "popup";

  return (
    <div className={popupClassName}>
      <div ref={popupRef} className={popupClassName2}>
        <div className="project-popup-flex">
          <div className="project-popup-text-container">
            <div className="project-banner project-banner-left">
              {project.status}
            </div>
            <div className="profile-popup-text-area">
              <div className="name-heading-popup text-padding project-popup-heading">
                {project.projectName}
              </div>
              <div className="description-text description-text-project">
                {project.methodology}
              </div>
              <div className="education-text">
                {`${project.location.address} | ${project.location.lat.toFixed(
                  4
                )}, ${project.location.long.toFixed(4)}`}
              </div>
              <div className="writeup-text text-padding">
                {project.description}
              </div>
              {project.extra && <div>{project.extra}</div>}
            </div>
          </div>
          <div className="project-popup-map-container">
            <Map
              ref={mapRef}
              initialViewState={{
                latitude: project.location.lat,
                longitude: project.location.long,
                zoom: 10,
              }}
              style={{ width: "100%", height: "100%" }}
              mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
              mapboxAccessToken={process.env.REACT_APP_MAPBOX}
            >
              <GeolocateControl position="bottom-right" />
              <NavigationControl position="bottom-right" showCompass={false} />
              <ScaleControl />
              <Marker
                latitude={project.location.lat}
                longitude={project.location.long}
              >
                <RoomIcon sx={{ color: "red", fontSize: "2.5rem" }} />
              </Marker>
            </Map>
          </div>
        </div>
        <div className="close-button-container">
          <CloseIcon className="close-button" onClick={onClose} />
        </div>
      </div>
    </div>
  );
};

export default ProjectPopup;
