import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home";

import Mission from "./components/Mission/Mission";
import Stats from "./components/Stats/stats";
import Profiles from "./components/Profiles/Profiles";
import Footer from "./components/Footer/Footer";
import Contact from "./components/Contact/Contact";
import Quality from "./components/Quality/quality";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactGA from "react-ga4";

import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import Projects from "./components/MarketingProjects/Projects";
import CrbonScope from "./components/CrbonScope/CrbonScope";

ReactGA.initialize("G-FCGH8KW7FP");

Amplify.configure(awsconfig);

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={
            <div className="site-wrapper">
              <Home />
              <Mission />
              <Quality />
              <Stats />
              <Projects />
              <CrbonScope />
              <Profiles />
              <Contact />
              <Footer />
            </div>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
