const awsconfig = {
  Auth: {
    Cognito: {
      userPoolId: "us-west-2_oorsskHwC",
      userPoolClientId: "67r56t86so6q1ocjphhv9d70a6",
    },
  },
};

export default awsconfig;
