export const textBlurbs = [
  "with the highest carbon offset potential.",
  "with the highest carbon intensity feed stock.",
  "connected to the electricity grid.",
  "suitable for conversion to solar energy.",
  "suitable for conversion to geothermal energy.",
  "suitable for conversion to small wind energy.",
  "suitable for conversion to clean energy storage.",
  "suitable for small scale direct air capture.",
];
