import { Element } from "react-scroll";
import { useState, useRef } from "react";
import { useIsVisible } from "../../Hooks/IsVisible";
import "./mission.css";

const Mission = () => {
  const visibleref = useRef();
  const isVisible = useIsVisible(visibleref);
  const visibleref2 = useRef();
  const isVisible2 = useIsVisible(visibleref2);

  const [animationPlayed, setAnimationPlayed] = useState(false);

  const handleAnimationEnd = () => {
    setAnimationPlayed(true);
  };
  const [animationPlayed2, setAnimationPlayed2] = useState(false);

  const handleAnimationEnd2 = () => {
    setAnimationPlayed2(true);
  };

  return (
    <Element name="mission" className="mission-container">
      <div className="mission-page wrapper">
        <div
          className={`mission-top-container mission-padding ${
            isVisible ? "fadeLeft" : ""
          } ${animationPlayed ? "animation-done" : ""}`}
          ref={visibleref}
          onAnimationEnd={handleAnimationEnd}
        >
          <div className="mission-text-primary-container">
            <div className="mission-header-container">
              <h1 className="heading-primary">Our Mission</h1>
            </div>
            <p
              style={{ marginBottom: "0" }}
              className="mission-text mission-text-primary"
            >
              At Carbon Labs,{" "}
              <span className="bold-span">
                our mission is to create a sustainable future that benefits us
                all.
              </span>{" "}
              We envision a world where greenhouse gas emissions (GHG) are
              permanently reduced, thousands of acres of land are restored, and
              local communities are supported.
            </p>
            <p className="mission-text mission-text-primary">
              <span className="bold-span">
                Our approach is rooted in innovation, technology, and
                cross-industry expertise.
              </span>{" "}
              We empower corporations to offset the emissions they are not yet
              able to address, by acquiring Crbon Labs’ high-quality credits,
              knowing that their purchase drives an{" "}
              <span className="bold-span">
                immediate, provable, and permanent elimination of GHG emissions
              </span>{" "}
              – in direct support of their organization’s Sustainability and
              Responsible Energy objectives.
            </p>
            <p className="mission-text mission-text-secondary">
              <span className="bold-span">
                We're shutting down, plugging, and decommissioning oil and gas
                wells that produce little-to-no oil and gas and restoring the
                land to make meaningful permanent change.
              </span>
            </p>
            <p className="mission-text mission-text-secondary">
              <span className="bold-span">
                Our commitment to quality is deeply ingrained in our core
                values.
              </span>{" "}
              We maintain the highest standards to ensure that we deliver
              quality offsets. We understand the importance and complexity of
              sustainability and are committed to helping you achieve your
              environmental objectives.
            </p>
          </div>
        </div>
        <div
          className={`mission-bottom-container mission-padding ${
            isVisible2 ? "fadeRight" : ""
          } ${animationPlayed2 ? "animation-done" : ""}`}
          ref={visibleref2}
          onAnimationEnd={handleAnimationEnd2}
        >
          <div className="mission-text-primary-container">
            <p className="mission-text mission-text-secondary mission-bottom-paragraph">
              Together, we can make a significant impact on GHG emissions and
              create a better future for ourselves and future generations.
              <span className="bold-span">
                {" "}
                Join us in our mission towards sustainability and make a
                difference that will last for years to come.
              </span>
            </p>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default Mission;
