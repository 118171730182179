/**
 * Types and deletes in a loop for given array of strings.
 *
 * @param typewriterElement - The HTML element where the typing effect will be applied. (ref)
 * @param revolvingTexts - An array of strings representing the texts that will be typed and deleted.
 */
export function startTypingEffect(typewriterElement, revolvingTexts) {
  let currentTextIndex = 0; // Index of the current text being typed
  let currentIndex = 0; // Index of the current word being typed
  let isDeleting = false;

  /**
   * Types and deletes the words in a typewriter-like effect.
   */
  const typeAndDelete = () => {
    const currentText = revolvingTexts[currentTextIndex];
    const words = currentText.split(" ");

    // Check if typewriterElement is null
    if (typewriterElement) {
      if (!isDeleting) {
        // If all words typed, go into deleting mode
        if (currentIndex >= words.length) {
          isDeleting = true;
          setTimeout(typeAndDelete, 3500); // Wait for 2 seconds before starting deletion
        } else {
          // Typing
          currentIndex++;
          typewriterElement.textContent = words
            .slice(0, currentIndex)
            .join(" "); // Update the text content with the current words
          typewriterElement.style.opacity = "1";
          setTimeout(typeAndDelete, 150);
        }
      } else {
        // Deleting mode
        // If all words have been deleted, go into typing mode
        if (currentIndex === 0) {
          isDeleting = false;
          currentIndex = 0;
          currentTextIndex = (currentTextIndex + 1) % revolvingTexts.length; // Move to the next text in the array (looping)
          setTimeout(typeAndDelete, 500); // Wait for 0.5 seconds before starting typing again
        } else {
          currentIndex--; // Move back to the previous word
          typewriterElement.textContent = words
            .slice(0, currentIndex)
            .join(" "); // Update the text content with the current words
          typewriterElement.style.opacity = "1"; // Make the text visible
          setTimeout(typeAndDelete, 50); // Delete the previous word after a short delay
        }
      }
    }
  };

  if (typewriterElement) {
    // Start the typing effect if the typewriter element exists
    typeAndDelete();
  }
}
