import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ContactPageIcon from "@mui/icons-material/Instagram";
import XIcon from "../../images/x-logo-black.png";
import LNIcon from "../../images/LI-In-Bug.png";
import delk from "../../images/Delk.png";
import solardrawing from "../../images/solar.png";
import CrbonLabsIcon from "../../images/crbon-labs-icon.png";

import "./footer.css";
import ReactGA from "react-ga4";

const Footer = () => {
  function openAppOrFallback(appUrl, webUrl, site) {
    const userAgent = navigator.userAgent.toLowerCase();
    ReactGA.event({
      category: "FOOTER_ICON_CLICK", // Event category (should match what you defined in GA)
      action: "FOOTER_ICON_CLICK",
      value: site,
    });
    // Check if the user agent indicates a mobile device
    if (/android|iphone|ipod|ipad/.test(userAgent)) {
      window.location.href = appUrl; // Attempt to open the app
    } else {
      // Not a mobile device, open the website directly
      window.open(webUrl, "_blank");
    }
  }
  return (
    <div className="footer-container wrapper">
      <div className="footer-section ">
        <div className="footer-content-container">
          <div className="footer-icons-container">
            <div
              className="footer-icon-container"
              href="https://www.linkedin.com/company/crbonlabs/"
              target="_blank"
              onClick={() =>
                openAppOrFallback(
                  "https://www.linkedin.com/company/crbonlabs/",
                  "https://www.linkedin.com/company/crbonlabs/",
                  0
                )
              }
            >
              <img className="xicon-img black-cover" src={LNIcon}></img>{" "}
            </div>

            <div
              className="footer-icon-container"
              onClick={() =>
                openAppOrFallback(
                  "twitter://user?screen_name=CrbonLabs",
                  "https://www.twitter.com/CrbonLabs",
                  1
                )
              }
            >
              <img className="xicon-img" src={XIcon}></img>{" "}
            </div>
          </div>

          <div className="footer-copyright-container">
            <p className="footer-copyright-text">
              © {new Date().getFullYear()} Crbon Labs Inc. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
