import React, { useRef, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';

const ProfilePopup = ({ isOpen, onClose, profile }) => {
  const popupRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target) && isOpen) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const popupClassName = isOpen ? 'profile-popup-container open' : 'profile-popup-container closed';
  const popupClassName2 = isOpen ? 'popup open' : 'popup';

  return (
    <div className={popupClassName}>
      <div ref={popupRef} className={popupClassName2}>
        <div className='profile-popup-flex'>
          <div className='profile-popup-img-container'>
            <img src={profile.popupImage} />
          </div>
          <div className='profile-popup-text-container'>
            <div className='profile-popup-text-area'>
              <div className='name-heading-popup text-padding'>{profile.name}</div>
              <div className='description-text '>{profile.description}</div>
              <div className='education-text'>{profile.education}</div>
              <div className='writeup-text text-padding'>{profile.writeup}</div>
              {profile.extra && <div>{profile.extra}</div>}
            </div>
          </div>
        </div>
        <div className='close-button-container'>
          <CloseIcon className='close-button' onClick={onClose} />
        </div>
      </div>
    </div>
  );
};

export default ProfilePopup;


