import { useState } from "react";
import { Link } from "react-scroll";
import CrbonLabsIconScrolled from "../../images/crbon-labs-icon.png";
import CrbonLabsIcon from "../../images/Crbon Labs logo PURE white.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef } from "react";
import "./navbar.css";
import ReactGA from "react-ga4";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ArrowDropDown } from "@mui/icons-material";
import { Button, Menu, MenuItem } from "@mui/material";
import { fetchUserAttributes, signOut } from "@aws-amplify/auth";
const Navbar = ({ isLoggedIn, setIsLoggedIn }) => {
  const navigate = useNavigate();
  const mobileWidth = useMediaQuery("(max-width:780px)");
  const inputRef = useRef();
  const [toggleMenu, setToggleMenu] = useState(false);
  const [scrolled, setScrolled] = useState(useState(window.scrollY > 15));
  const [logoTranslation, setLogoTranslation] = useState(0);
  const [scaleFactor, setScaleFactor] = useState(0);
  const [topTransform, setTopTransform] = useState(0);
  const [transformYType, setTransformYType] = useState("vw");

  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const [username, setUsername] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    console.log("something changed");
    const checkAuthState = async () => {
      try {
        if (isLoggedIn) {
          const { name } = await fetchUserAttributes();
          setUsername(name);
        }
      } catch (err) {
        console.log(err);
      }
    };
    checkAuthState();
  }, [isLoggedIn]);

  // Handle Menu interactions
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    // Implement logout functionality
    try {
      await signOut();
      console.log("User logged out");
      setIsLoggedIn(false);
      setAnchorEl(null);
      navigate("/login");
    } catch (err) {
      console.log(err);
    }
  };

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (
        window.scrollY > lastScrollY &&
        window.scrollY > (isHomePage ? 500 : 100)
      ) {
        console.log("test1");
        // if scroll down hide the navbar
        setShow(false);
      } else {
        // if scroll up show the navbar
        setShow(true);
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };

  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  const imageRef = useRef(null);
  const [widthInPixels, setWidthInPixels] = useState(null);
  const [resizerValue, setResizerValue] = useState(null);

  useEffect(() => {
    if (viewportWidth && widthInPixels) {
      const value = (widthInPixels / viewportWidth) * 0.2173913043;
      setResizerValue(value);
      //('image-width', widthInPixels);
    }
  }, [viewportWidth, widthInPixels]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  useEffect(() => {
    const html = document.getElementsByTagName("html")[0];

    if (toggleMenu) {
      html.classList.add("lock-scroll");
    } else {
      html.classList.remove("lock-scroll");
    }
    return () => {
      html.classList.remove("lock-scroll");
    };
  }, [toggleMenu]);

  useEffect(() => {
    const onScroll = () => {
      //console.log(window.scrollY);
      if (window.scrollY > 25) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    // Check if the user has already scrolled down on page load
    if (window.scrollY > 15) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      var screenWidth = window.innerWidth;
      var transformYvalue = 16;
      var type = "vw";
      //console.log('width', resizerValue);
      if (screenWidth > 2250) {
        screenWidth = 2250;
        type = "px";
        transformYvalue = 500;
      }

      if (screenWidth < 701) {
        transformYvalue = 35;
      }
      //console.log(imageRef.current.naturalHeight)
      var logoWidth = 648; // Adjust this to match the width of your logo
      const padding = 16;
      const smallLogoWidth = 140.8;
      if (0.9 * screenWidth < logoWidth) {
        logoWidth = 0.9 * screenWidth;
      }
      var translationValue = (screenWidth - logoWidth) / 2 - padding;
      var scalefactorlogo = smallLogoWidth / logoWidth;

      if (translationValue + padding < 0) {
        translationValue = 0;
      }
      setScaleFactor(scalefactorlogo);
      setLogoTranslation(translationValue);
      setTopTransform(transformYvalue);
      setTransformYType(type);
    };

    // Initial calculation and add resize listener
    handleResize();
    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [scrolled]);

  const calculateOffset = (elementName) => {
    if (show && typeof window !== "undefined") {
      const targetElement = document.getElementsByName(elementName)[0];
      inputRef.current = targetElement;
      if (targetElement) {
        const { top } = inputRef.current.getBoundingClientRect();
        // console.log(top);
        return top > 0 ? 0 : -80;
      }
    }
    return undefined;
  };

  useEffect(() => {
    if (isHomePage) {
      const onScroll = () => {
        setScrolled(window.scrollY > 25);
      };
      window.addEventListener("scroll", onScroll);
      return () => window.removeEventListener("scroll", onScroll);
    }
  }, [isHomePage]);

  return (
    <>
      <div
        className={`navbar-container wrapper ${
          isHomePage ? (scrolled ? "scrolled" : "") : "scrolled"
        } ${show ? "show" : ""}`}
        style={show ? { top: "0" } : { top: "-100px" }}
      >
        <div
          className={`navbar-content-container ${
            isHomePage ? (scrolled ? "scrolled" : "") : "scrolled"
          }`}
        >
          <div className="navbar-icon-and-name-container">
            <span className="screenreader-text">Crbon Labs</span>
            <a
              href="/"
              className={`${scrolled ? "scrolled" : ""} header__logo`}
            >
              {isHomePage ? (
                <img
                  className={`${scrolled ? "scrolled" : ""}`}
                  style={
                    !scrolled
                      ? {
                          transform: `translate3d(${logoTranslation}px, ${topTransform}${transformYType}, 0)`,
                        }
                      : scrolled
                      ? {
                          transform: `translate3d(0, -50%, 0) scale(${scaleFactor})`,
                        }
                      : {}
                  }
                  src={scrolled ? CrbonLabsIconScrolled : CrbonLabsIcon}
                ></img>
              ) : (
                <img className="scrolled" src={CrbonLabsIconScrolled}></img>
              )}
            </a>
          </div>
          {mobileWidth ? (
            <div>
              <div className="navbar-menu-icon">
                <MenuIcon
                  onClick={() => {
                    setToggleMenu(true);
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="navbar-links-container">
              <Link
                activeClass="active"
                className="navbar-link navbar-link-color-animation"
                to="mission"
                spy={true}
                smooth={true}
                duration={500}
                offset={calculateOffset("mission")}
                onClick={() =>
                  ReactGA.event({
                    category: "NAV_CLICK_DESKTOP", // Event category (should match what you defined in GA)
                    action: "NAV_CLICK_DESKTOP",
                    label: "MISSION",
                    value: 1,
                    // Event action
                  })
                }
              >
                Mission
              </Link>
              <Link
                activeClass="active"
                className="navbar-link navbar-link-color-animation"
                to="Quality"
                spy={true}
                smooth={true}
                duration={500}
                offset={calculateOffset("quality")}
                onClick={() =>
                  ReactGA.event({
                    category: "NAV_CLICK_DESKTOP", // Event category (should match what you defined in GA)
                    action: "NAV_CLICK_DESKTOP",
                    label: "QUALITY",
                    value: 2,
                    // Event action
                  })
                }
              >
                Quality
              </Link>

              <Link
                activeClass="active"
                className="navbar-link navbar-link-color-animation"
                to="projects"
                spy={true}
                smooth={true}
                duration={500}
                onClick={() => {
                  console.log("projects_nav clicked");
                  ReactGA.event({
                    category: "NAV_CLICK_DESKTOP", // Event category (should match what you defined in GA)
                    action: "NAV_CLICK_DESKTOP",
                    label: "PROJECTS",
                    value: 0,
                    // Event action
                  });
                }}
              >
                Projects
              </Link>
              <Link
                activeClass="active"
                className="navbar-link navbar-link-color-animation"
                to="crbonscope"
                spy={true}
                smooth={true}
                duration={500}
                onClick={() => {
                  console.log("crbonscope_nav clicked");
                  ReactGA.event({
                    category: "NAV_CLICK_DESKTOP", // Event category (should match what you defined in GA)
                    action: "NAV_CLICK_DESKTOP",
                    label: "CRBONSCOPE",
                    value: 0,
                    // Event action
                  });
                }}
              >
                Crbonscope
              </Link>
              <Link
                activeClass="active"
                className="navbar-link navbar-link-color-animation"
                to="team"
                spy={true}
                smooth={true}
                duration={500}
                offset={calculateOffset("team")}
                onClick={() =>
                  ReactGA.event({
                    category: "NAV_CLICK_DESKTOP", // Event category (should match what you defined in GA)
                    action: "NAV_CLICK_DESKTOP",
                    label: "TEAM",
                    value: 3,
                    // Event action
                  })
                }
              >
                Team
              </Link>

              {isLoggedIn === null ? (
                // Placeholder to avoid showing incorrect state
                <div style={{ minWidth: "100px" }}></div>
              ) : isLoggedIn ? (
                <div className="user-menu">
                  <Button
                    onClick={handleMenuClick}
                    endIcon={<ArrowDropDown />}
                    style={{
                      textTransform: "none",
                      color: "#8C1C56",
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                    }}
                  >
                    {username}
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    disableScrollLock
                    dense
                    sx={{
                      "& .MuiMenu-paper": {
                        width: "10rem",
                      },
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </div>
              ) : (
                <Link
                  activeClass="active"
                  className="navbar-link navbar-login-button"
                  to="contact"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={() => {
                    console.log("contact_nav clicked");
                    ReactGA.event({
                      category: "NAV_CLICK_DESKTOP", // Event category (should match what you defined in GA)
                      action: "NAV_CLICK_DESKTOP",
                      label: "CONTACT",
                      value: 0,
                      // Event action
                    });
                  }}
                >
                  REACH-OUT
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
      <div className={`navbar-menu-container ${toggleMenu ? "open" : ""}`}>
        <div className="navbar-container show scrolled">
          <div className="navbar-content-container scrolled">
            <div className="navbar-icon-and-name-container"></div>
            <div>
              <div className="navbar-close-icon">
                <CloseIcon
                  onClick={() => {
                    setToggleMenu(false);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="menu-links-container">
          <Link
            onClick={() => {
              setToggleMenu(false);
              ReactGA.event({
                category: "NAV_CLICK_MOBILE", // Event category (should match what you defined in GA)
                action: "NAV_CLICK_MOBILE",
                label: "MISSION",
                value: 1,
                // Event action
              });
            }}
            activeClass="active"
            className="navbar-link navbar-link-color-animation navbar-mobile-link"
            to="mission"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
          >
            Mission
          </Link>
          <Link
            onClick={() => {
              setToggleMenu(false);
              ReactGA.event({
                category: "NAV_CLICK_MOBILE", // Event category (should match what you defined in GA)
                action: "NAV_CLICK_MOBILE",
                label: "QUALITY",
                value: 2,
                // Event action
              });
            }}
            activeClass="active"
            className="navbar-link navbar-link-color-animation navbar-mobile-link"
            to="quality"
            spy={true}
            smooth={true}
            offset={calculateOffset("quality")}
            duration={500}
          >
            Quality
          </Link>

          <Link
            onClick={() => {
              setToggleMenu(false);
              ReactGA.event({
                category: "NAV_CLICK_MOBILE", // Event category (should match what you defined in GA)
                action: "NAV_CLICK_MOBILE",
                label: "PROJECTS",
                value: 0,
                // Event action
              });
            }}
            activeClass="active"
            className="navbar-link navbar-link-color-animation navbar-mobile-link"
            to="projects"
            spy={true}
            smooth={true}
            offset={calculateOffset("home")}
            duration={500}
          >
            Projects
          </Link>
          <Link
            onClick={() => {
              setToggleMenu(false);
              ReactGA.event({
                category: "NAV_CLICK_MOBILE", // Event category (should match what you defined in GA)
                action: "NAV_CLICK_MOBILE",
                label: "CRBONSCOPE",
                value: 0,
                // Event action
              });
            }}
            activeClass="active"
            className="navbar-link navbar-link-color-animation navbar-mobile-link"
            to="crbonscope"
            spy={true}
            smooth={true}
            offset={calculateOffset("home")}
            duration={500}
          >
            Crbonscope
          </Link>
          <Link
            onClick={() => {
              setToggleMenu(false);
              ReactGA.event({
                category: "NAV_CLICK_MOBILE", // Event category (should match what you defined in GA)
                action: "NAV_CLICK_MOBILE",
                label: "TEAM",
                value: 3,
                // Event action
              });
            }}
            activeClass="active"
            className="navbar-link navbar-link-color-animation navbar-mobile-link"
            to="team"
            spy={true}
            smooth={true}
            offset={calculateOffset("team")}
            duration={500}
          >
            Team
          </Link>
          <Link
            onClick={() => {
              setToggleMenu(false);
              ReactGA.event({
                category: "NAV_CLICK_MOBILE", // Event category (should match what you defined in GA)
                action: "NAV_CLICK_MOBILE",
                label: "CONTACT",
                value: 4,
                // Event action
              });
            }}
            activeClass="active"
            className="navbar-link navbar-link-color-animation navbar-mobile-link"
            to="contactform"
            spy={true}
            smooth={true}
            duration={500}
          >
            REACH-OUT
          </Link>
        </div>
      </div>
    </>
  );
};

export default Navbar;
